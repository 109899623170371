<template>
  <div>
    <div class="app_title_box">
      <h4>Footer Content</h4>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="createPageContent"
      >
        Save Changes
      </b-button>
    </div>
    <section class="footer__view__box">

      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-3 mb-3 footer-content_view">
            <h1
              class=""
            >
              <img
                :src="Logo"
                class="img-fluid"
                alt="logo"
                style="width: 200px !important"
              >
            </h1>
            <!-- <b-form-input
              v-model="footers.newsletter"
              size=""
              class="mt-2 mb-2"
            /> -->
            <!-- <p class="mt-2">
            Sign-up to receive regular updates from us.
          </p> -->
            <!-- <div class="subscribe_box">
              <input placeholder="Enter your email">
              <feather-icon
                size="20"
                icon="ArrowRightIcon"
              />
            </div> -->
          </div>
          <div
            class="col-12 col-sm-12 mb-3 col-md-3"
          >
            <h4 class="footer_header d-flex align-items-center justify-content-between">
              Company
            </h4>
            <draggable
              v-model="footers.company"
              class="list-group list-group-flush cursor-move"
              tag="li"
              @change="draggableChange"
            >
              <transition-group
                type="transition"
                name="flip-list"
              >
                <b-list-group-item
                  v-for="link in footers.company"
                  :key="link.name"
                  tag="li"
                  class="d-flex align-items-center"
                >
                  <b-form-input
                    v-model="link.name"
                    size="sm"
                    class="mr-2"
                  />
                  <b-form-checkbox
                    v-model="link.is_visible"
                    :checked="link.is_visible === true ? true : false"
                    class="custom-control-success"
                  />
                </b-list-group-item>
              </transition-group>
            </draggable>
          </div>
          <div
            class="col-12 col-sm-12 mb-3 col-md-3"
          >
            <h4 class="footer_header">
              Support
            </h4>
            <draggable
              v-model="footers.support"
              class="list-group list-group-flush cursor-move"
              tag="li"
              @change="draggableChange"
            >
              <transition-group
                type="transition"
                name="flip-list"
              >
                <b-list-group-item
                  v-for="link in footers.support"
                  :key="link.name"
                  tag="li"
                  class="d-flex align-items-center"
                >
                  <b-form-input
                    v-model="link.name"
                    size="sm"
                    class="mr-2"
                  />
                  <b-form-checkbox
                    v-model="link.is_visible"
                    :checked="link.is_visible === true ? true : false"
                    class="custom-control-success"
                  />
                </b-list-group-item>
              </transition-group>
            </draggable>
          </div>
          <div class="col-12 col-sm-12 mb-3 col-md-3">
            <h4 class="footer_header">
              Contact Us
            </h4>
            <span class="footer_item">Email: {{ contact.email }}</span>
            <span class="footer_item">Phone: {{ contact.phone }}</span>
            <span class="footer_item">Address: <br>{{ contact.address }}
              <br> {{ contact.city }}, {{ contact.state }} {{ contacts && contacts.zip_code }}</span>
            <div class="social_link mt-2">
              <feather-icon
                size="20"
                icon="FacebookIcon"
              />
              <feather-icon
                size="20"
                icon="TwitterIcon"
              />
              <feather-icon
                size="20"
                icon="YoutubeIcon"
              />
              <feather-icon
                size="20"
                icon="InstagramIcon"
              />
            </div>
          </div>
        </div>

        <!-- end of footer view -->
        <div class="sub__footer_view">
          <b-form-input
            v-model="footers.copyright"
            size=""
            class=""
            style="width: 500px;"
          />
        <!-- <p>2023 © Hyper Marketing Live. All rights reserved.</p> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BFormInput, BListGroupItem, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Logo from '../../../assets/images/logo/logo.png'

export default {
  components: {
    BFormInput,
    BListGroupItem,
    draggable,
    BFormCheckbox,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Logo,
      about: '',
      footers: {
        company: [
          { name: 'About', url: 'about-us', is_visible: true },
          { name: 'FAQ', url: 'faqs', is_visible: true },
          { name: 'Blog', url: 'blogs', is_visible: true },
          { name: 'Contact Us', url: 'contact-us', is_visible: true },
        ],
        support: [
          { name: 'Help Center', url: 'help-center', is_visible: true },
          { name: 'Privacy Policy', url: 'privacy-policy', is_visible: true },
          { name: 'Terms', url: 'terms', is_visible: true },
        ],
        copyright: '2023 © Hyper Marketing Live. All rights reserved.',
      },
      contact: {
        email: '',
        phone: '',
        address: '',
        state: '',
        city: '',
        zip_code: '',
      },
    }
  },
  computed: {
    contents() {
      return this.$store.state?.page_content?.page_footer_contents
    },
    contacts() {
      return this.$store.state?.page_content?.contact_contents?.content
    },
  },
  watch: {
    '$store.state.page_content.page_footer_contents': {
      handler(oldValue, newValue) {
        if (oldValue.content !== null) {
          this.footers = oldValue.content
        }

        if (newValue && newValue.content !== null) {
          this.footers = newValue.content
        }
      },
      deep: true,
    },
    '$store.state.page_content.contact_contents': {
      handler(oldValue, newValue) {
        if (oldValue.content !== null) {
          this.contact = oldValue.content
        }
        if (newValue && newValue.content !== null) {
          this.contact = newValue.content
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getContent()
  },
  methods: {
    gotoHome() {
      this.$router.push({ name: 'home' }).catch(() => {})
    },
    draggableChange() {
      const data = { id: this.contents.id, name: 'footer', content: this.footers }
      this.$store.dispatch('page_content/updateContent', data)
    },
    getContent() {
      this.$store.dispatch('page_content/getAllContactContent', 'contacts')
      this.$store.dispatch('page_content/getAllFooterContent', 'footer')
    },
    createPageContent() {
      if (this.contents.content === undefined || this.contents.content === null) {
        const data = { name: 'footer', content: this.footers }
        this.$store.dispatch('page_content/createContent', data)
      } else {
        const data = { id: this.contents.id, name: 'footer', content: this.footers }

        this.$store.dispatch('page_content/updateContent', data)
      }
    },
  },
}
</script>

<style>
.list-group-item {
  transition: all 0.5s
}
</style>
