<template>
  <div>
    <b-card>
      <FooterContent />
    </b-card>

    <!-- <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate' : 'Add New'"
      hide-footer
    >
      <b-card-text>
        <h5>Check First Paragraph</h5>
        Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie.
        Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice.
      </b-card-text>
    </b-modal> -->
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable,
} from 'bootstrap-vue'
import FooterContent from './components/SiteFooterContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    FooterContent,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      items: [
        {
          age: 40, first_name: 'Dickerson', last_name: 'Macdonald', Occupation: 'Job',
        },
        {
          age: 21, first_name: 'Larsen', last_name: 'Shaw', Occupation: 'Job',
        },
        {
          age: 89, first_name: 'Geneva', last_name: 'Wilson', Occupation: 'Bussiness',
        },
        {
          age: 38, first_name: 'Jami', last_name: 'Carney', Occupation: 'Bussiness',
        },
        {
          age: 40, first_name: 'James', last_name: 'Thomson', Occupation: 'Job',
        },
      ],
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
    },
    showDetails() {
      this.is_update = true
      this.showModal()
    },
  },
}
</script>

<style>

</style>
